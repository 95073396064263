import React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";

function NotFoundPage() {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <div className="text-center text-xl">
        <h1 className="text-3xl">404</h1>
        <p>
          Go{" "}
          <Link to="/" className="underline">
            back
          </Link>
        </p>
      </div>
    </Layout>
  );
}

export default NotFoundPage;
